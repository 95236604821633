body {
  font:12px Arial, helvetica, sans serif;
  overflow-x:hidden;
  overflow-y:auto;
}

#container {
  z-index:1;
  position:absolute;
  top:5px;
  left:50%;
  margin-left:-450px;
  width:900px;
  background:#fff url(../img/background.jpg) repeat-y;
  overflow:visible;
}

#header {
  z-index:2;
  position:relative;
  top:0;
  left:0;
  width:900px;
  height:254px;
  background:#fff url(../img/stille.jpg) no-repeat;
  overflow:hidden;
}

#menu {
  z-index:2;
  position:relative;
  float:left;
  top:10px;
  left:24px;
  width:146px;
  font:14px Arial, helvetica, sans serif;
  color:#663333;
  overflow-x:hidden;
  overflow-y:auto;
  border:0;
}

#menu ul {
  list-style:none;
  line-height:1;
  padding:0;
  margin:0;
}

#menu li {
  padding:0 0 15px 0;
  margin:0;
}

#menu li a, a:active, a:visited, a:link {
  text-decoration:none;
  color:#663333;
}

#menu li a:hover {
  text-decoration:none;
  color:#3399ff;
}

#content {
  z-index:2;
  position:relative;
  float:left;
  top:10px;
  left:55px;
  width:460px;
  font:12px Arial, helvetica, sans serif;
  color:#663333;
  border:none;
  background:#fff url(../img/ort_der_stille.jpg) no-repeat;
  padding:125px 0 0 0;
}

#content_start {
  z-index:2;
  position:relative;
  float:left;
  top:10px;
  left:55px;
  width:460px;
  font:12px Arial, helvetica, sans serif;
  color:#663333;
  border:none;
  background:#fff;
  padding:80px 0 0 0;
}

#info {
  z-index:99;
  margin-top: -250px;
  margin-left: -150px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 350px;
  height: 400px;
  padding:5px;
  overflow-x:hidden;
  overflow-y:scroll;
  border:3px solid #3399cc;
  font-size:11px;
  background-color:#fff;
}

#gebet {
  z-index:5;
  position:absolute;
  top:274px;
  left:684px;
  width:160px;
  height:100px;
  border:none;
  font:11px Arial, helvetica, sans serif;
  color:#663333;
}

#bild {
  z-index:3;
  display:block;
  clear:both;
  position:absolute;
  bottom:-10px;
  left:692px;
  width:208px;
  height:278px;
  border:none;
}

*html #bild {
  bottom:-14px;
}

#footer {
  z-index:2;
  position:relative;
  clear:both;
  top:10px;
  left:0;
  width:900px;
  height:57px;
  background:#fff url(../img/footer.jpg) no-repeat;
  overflow:hidden;
}