/*****************************/
/*** Core html setup stuff ***/
/*****************************/

html {
  height: 100%;
  margin-bottom: 1px;
  font-family:Arial, helvetica, sans serif;
}

form {
  margin: 0;
  padding: 0 5px 0 5px;
}

a:link {color:#3399ff; text-decoration:none;}

a:visited {color:#3399ff; text-decoration:none;}

a:active {color:#3399ff; text-decoration:none;}

a:hover {color:#3399ff; text-decoration:underline;}

a:visited:hover {color:#3399ff; text-decoration:underline;}


input.button { cursor: pointer; }

p { margin-top: 0; font:12px;}

td {}

span {}

img { border: 0 none; }

div.offline {
	background: #fffebb;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	font-size: 1.2em;
	padding: 5px;
}

/* headers */
div.componentheading, div.componentheading_news {
  padding: 2px 0 0 5px;
}

h1, .ueberschrift1, .componentheading, .componentheading_news, .contentheading {
	padding: 0 3px 0 0;
	font-size:14px;
        font-weight:bold;
	color: #663333;
	text-align: left;
	width: 100%;
        margin:0;
	border:0;
}

h2, .ueberschrift2 {
	padding: 0 3px 0 0px;
	font-size:13px;
        font-weight:bold;
	color: #663333;
	text-align: left;
        margin:0;
}

table.blog td.contentheading {
        text-align:left;
}

table.contentpaneopen h3 {
	margin-top: 25px;
}

h3, .ueberschrift3 {
	color: #663333;
    font-weight:bold;
    border:0;
}

h4, .ueberschrift4 {
	color: #663333;
}

h3, table.moduletable th, legend {
  	margin: 0 0 5px 0;
  	font-size:13px;
    font-weight:bold;
	text-align: left;
	color:#663333;
}

/* small text */
.small, .smalltext {
	font-size: 10px;
	color: #999;
	font-weight: normal;
	text-align: left;
}

.modifydate {
  	height: 20px;
  	vertical-align: bottom;
	font-size: 10px;
	color: #999;
	font-weight: normal;
	text-align: left;
}

.createdate {
	height: 20px;
	vertical-align: top;
	font-size: 10px;
	color: #999;
	font-weight: normal;
	vertical-align: top;
	padding-bottom: 5px;
	padding-top: 0px;

}

a.readmore {
	color:#663333;
        display:inline;
}





table.contentpaneopen {



        padding:0 5px 0 15px;



	font-size:12px;



	color:#663333;



	line-height:1.5;



         border:0;



}

.contentpaneopen span.normal {
}


table.contentpaneopen img {

margin:0px;
border:0;


}



table.contentpaneopen table {



	font-size:12px;
        color:#663333;



}



table.contentpaneopen table tr{



	vertical-align:top;



}







table.contentpaneopen ul{

line-height:1.5;
list-style-type:square;

}







table.contentpaneopen ul li{



	line-height:1.5;



}







a.contentpagetitle,



a.contentpagetitle:hover,



a.contentpagetitle:active,



a.contentpagetitle:focus {



	text-decoration: none !important;



}


table.contentpaneopen, table.contentpane, table.blog {



	width: 100%;



}







.moduletable {



	padding: 0;



}





ul.pagination {



	margin: 15px auto;



	padding: 10px 0;



}







ul.pagination li {



	margin: 0;



	padding: 0;



	display: inline;



	background: none;



}







ul.pagination li span{



	padding: 2px 5px;



}







ul.pagination a {



	padding: 2px 5px;



	border: none;



}







ul.pagination a:hover, ul.pagination a:active, ul.pagination a:focus {



	border: none;



	background: #FFFFFF;



}







.pagenavbar {



	padding-left: 14px;



}







.pagenavcounter {



	padding: 8px 14px 0 14px;



}





.moduletable {



	 margin:0 0 0 0;



         padding:0;



         border:0;



}







.moduletable ul.latestnews {



        list-style:square;



	margin:0;



	padding:0 0 0 35px;



	border:0;



        color:#00519d;



}



.moduletable .contentheading {

        font-size:12px;

        color:#663333;

        font-weight:bold;

}









.moduletable table.contentpaneopen {



	margin:0;



        padding:0;



	font-size:12px;



	color:#663333;



}







.moduletable td.contentheading {



	font-size:12px;



	color:#663333;



        font-weight:bold;



         padding:0;



         margin:0;



         border:0;



         background:transparent;



}







/* Clearfix */



.clearfix:after {



	clear: both;



	display: block;



	content: ".";



	height: 0;



	visibility: hidden;



}







* html > body .clearfix {



	width: 100%;



	display: block;



}







* html .clearfix {



	height: 1%;



}







/* Firefox Scrollbar Hack - Do not remove */



html {



	margin-bottom: 1px;



	height: 100%!important;



	height: auto;



}